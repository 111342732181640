// @mui material components
import { Forest } from "@mui/icons-material";
import Card from "@mui/material/Card";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ForecastCard({ title, address }) {
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox p={2}>
        <SoftTypography variant="h5" fontWeight="bold" color="dark">
          {title}
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="medium" color="text">
          {address}
        </SoftTypography>
        <SoftBox pt={2} display="flex" justifyContent="space-between" alignItems="center">
          <SoftTypography variant="button" color="text" fontWeight="medium">
            Day
          </SoftTypography>
          <SoftTypography variant="button" color="text" fontWeight="medium">
            Polution level
          </SoftTypography>
        </SoftBox>
        <SoftBox py={2} display="flex" justifyContent="space-between" alignItems="center">
          <SoftBox bgColor="white" borderRadius="lg" shadow="sm" opacity={0.8} p={2}>
            <SoftTypography variant="button" color="dark" fontWeight="medium">
              Today
            </SoftTypography>
          </SoftBox>
          <SoftBox
            color="white"
            bgColor="success"
            variant="gradient"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            py={1}
            px={2}
          >
            <SoftBox display="flex" flexDirection="column" alignItems="center">
              <SoftTypography variant="button" color="white" fontWeight="medium">
                Good
              </SoftTypography>
              <SoftTypography variant="button" color="white" fontWeight="medium">
                24 US AQI
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of StatisticsCard
Forest.defaultProps = {
  color: "info",
};

// Typechecking props for the StatisticsCard
ForecastCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  address: PropTypes.string,
};

export default ForecastCard;
