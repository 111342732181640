// Soft UI Dashboard React layouts
import MainDashboard from "layouts/pages/mainDashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import SignOut from "layouts/authentication/sign-out";
import RoadConditionsDashboard from "layouts/pages/roadConditions";
import NoisePollutionDashboard from "layouts/pages/noisePollution";
import AirQualityDashboard from "layouts/pages/airQuality";
import RoadIlluminationDashboard from "layouts/pages/roadIllumination";

// Soft UI Dashboard React icons
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";
import HearingIcon from "@mui/icons-material/Hearing";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import AirIcon from "@mui/icons-material/Air";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";

const routes = [
  {
    type: "collapse",
    name: "Road conditions",
    key: "road-conditions",
    route: "/road-conditions",
    icon: <StackedLineChartIcon size="12px" />,
    protected: false,
    component: RoadConditionsDashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Road illumination",
    key: "road-illumination",
    route: "/road-illumination",
    icon: <LightbulbOutlinedIcon size="12px" />,
    protected: false,
    component: RoadIlluminationDashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Noise pollution",
    key: "noise-pollution",
    route: "/noise-pollution",
    icon: <HearingIcon size="12px" />,
    protected: false,
    component: NoisePollutionDashboard,
    noCollapse: true,
  },
  {
    type: "none",
    name: "Indicators",
    key: "dashboard",
    route: "/dashboard",
    icon: <WifiTetheringIcon size="12px" />,
    protected: false,
    component: MainDashboard,
    noCollapse: true,
  },
  {
    type: "none",
    name: "Air Quality",
    key: "air-quality",
    route: "/air-quality",
    icon: <AirIcon size="12px" />,
    protected: false,
    component: AirQualityDashboard,
    noCollapse: true,
  },
  { type: "title", title: "Profile", key: "account-pages" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: Profile,
    noCollapse: true,
    protected: true,
  },
  {
    type: "none",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: SignIn,
    noCollapse: true,
  },
  {
    type: "none",
    name: "Sign Up",
    key: "sign-up",
    route: "/authentication/sign-up",
    icon: <SpaceShip size="12px" />,
    component: SignUp,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "sign-out",
    route: "/authentication/sign-out",
    icon: <SpaceShip size="12px" />,
    component: SignOut,
    noCollapse: true,
  },
];

export default routes;
