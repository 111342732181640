// react-router-dom components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// @mui material components
import { Paper, Icon } from "@mui/material";

function MapMarkerInfo({ properties, src, onClose }) {
  return (
    <>
      {properties && (
        <Paper
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            width: "20%",
            height: "200px",
            borderRadius: "5px",
            padding: "10px",
            zIndex: 1000,
            backgroundColor: properties.color,
          }}
          square={false}
        >
          <SoftBox component="img" src={src} alt="Product Image" shadow="sm" maxWidth="100%" />
          <SoftBox pl={1}>
            <SoftTypography
              variant="h6"
              color="white"
              fontWeight="bold"
              mt={2}
              sx={{ lineHeight: "0px" }}
            >
              {properties.name ? properties.name.replace("-", " ") : ""}
            </SoftTypography>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={1}>
              <SoftTypography variant="caption" color="white">
                Detected 23 min ago
              </SoftTypography>
              <SoftButton variant="text" color="white" size="medium" iconOnly onClick={onClose}>
                <Icon>close</Icon>
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Paper>
      )}
    </>
  );
}

// Typechecking props for the MapMarkerInfo
MapMarkerInfo.propTypes = {
  src: PropTypes.string,
  properties: PropTypes.object,
  onClose: PropTypes.func,
};

export default MapMarkerInfo;
