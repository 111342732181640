// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Images
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";
import moment from "moment";

function StatisticsCard({ date, properties, measurement, action }) {
  const sharedStyles = {
    transform: `translateZ(50px) scale(0.7)`,
    transition: "all 500ms linear",
  };

  const actionStyles = {
    my: 1,
    width: ({ functions: { pxToRem } }) => pxToRem(160),
    ...sharedStyles,

    "&:hover, &:focus": {
      transform: `translateZ(50px) scale(0.7)`,
    },
  };

  return (
    <>
      {properties && (
        <Card
          sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
            backgroundImage: gradients[properties.color]
              ? `${linearGradient(
                  rgba(properties.color, 0.85),
                  rgba(properties.color, 0.85)
                )}, url(${whiteCurved})`
              : `${linearGradient(
                  rgba(properties.color, 0.55),
                  rgba(properties.color, 0.95)
                )}, url(${whiteCurved})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "visible",
            height: "100%",
          })}
        >
          <SoftBox p={2} display="flex" flexDirection="column" alignItems="center">
            <SoftTypography variant="caption" fontWeight="light" color="white" alignSelf="start">
              Last update at
            </SoftTypography>
            <SoftTypography variant="h6" fontWeight="medium" color="white" alignSelf="start">
              {moment(date).format("hh:mm, DD MMM")}
            </SoftTypography>
            <SoftBox>
              <SoftTypography
                variant="h2"
                color="white"
                textTransform="capitalize"
                fontWeight="bold"
                alignSelf="center"
                sx={{
                  mt: 1,
                  ...sharedStyles,
                }}
              >
                {properties.state}
              </SoftTypography>
            </SoftBox>
            <SoftTypography variant="caption" fontWeight="light" color="white" alignSelf="center">
              {properties.address}
            </SoftTypography>
            <SoftTypography variant="h4" fontWeight="bold" color="white">
              {properties.number} {measurement}
            </SoftTypography>
            <SoftTypography variant="caption" fontWeight="light" color="white" alignSelf="center">
              Average score
            </SoftTypography>
            {action.type === "internal" ? (
              <SoftButton
                component={Link}
                to={action.route}
                variant="outlined"
                size="medium"
                color="white"
                sx={actionStyles}
              >
                {action.label}
              </SoftButton>
            ) : (
              <SoftButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                color="white"
                size="medium"
                sx={actionStyles}
              >
                {action.label}
              </SoftButton>
            )}
          </SoftBox>
        </Card>
      )}
    </>
  );
}

// Setting default values for the props of StatisticsCard
StatisticsCard.defaultProps = {
  date: moment(),
  properties: { state: "", color: "#fffff", number: 0 },
};

// Typechecking props for the StatisticsCard
StatisticsCard.propTypes = {
  measurement: PropTypes.string.isRequired,
  date: PropTypes.object,
  properties: PropTypes.object,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default StatisticsCard;
