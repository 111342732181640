import PropTypes from "prop-types";
import { useState } from "react";

// @mui material components
import Fade from "@mui/material/Fade";
import { Card } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Maplibre;
import { MapLibreMap } from "@mapcomponents/react-maplibre";
import "./styles.css";

// Component card
import MapLegendCard from "components/MapCard/MapLegendCard";
import ControllerCard from "components/ControllerCard";
import MapPointLeyer from "components/MapCard/MapLayers/MapPointLayer";
import MapLinestringLeyer from "components/MapCard/MapLayers/MapLinestringLayer";

//data
import sampleData from "api/data/roadConditionsSambleData";
import mapSourceData from "api/data/mapSourceData";

const mapOptions = {
  zoom: 11,
  style: "https://wms.wheregroup.com/tileserver/style/osm-bright.json",
  center: [12.519722, 55.72899, 26.24],
};

// eslint-disable-next-line no-unused-vars
function AirQualityMap({ source, getProperties }) {
  const [windConditions, setWindConditions] = useState(false);
  const features = sampleData.data.rows.map((row) => row[0]);
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundSize: "cover",
        backgroundPosition: "50%",
        overflow: "visible",
        height: "100%",
      }}
    >
      <SoftBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          position: "relative",
          minHeight: pxToRem(350),

          [breakpoints.up("xl")]: {
            height: "100%",
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <Fade in timeout={850}>
          <SoftBox width="100%" height="100%" position="relative" borderRadius="lg">
            <SoftBox
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              borderRadius="lg"
              sx={{ objectFit: "cover", objectPosition: "center" }}
            />

            <MapLibreMap options={mapOptions} mapId="air-quality-map" />
            <MapLinestringLeyer
              mapId="air-quality-map"
              layerId="air-quality-layer"
              onHandleClick={getProperties}
              source={{
                type: "geojson",
                data: { type: "FeatureCollection", features: features },
              }}
              visible={true}
            ></MapLinestringLeyer>
            <MapPointLeyer
              mapId="air-quality-map"
              layerId="wind-conditions"
              onHandleClick={getProperties}
              source={mapSourceData}
              visible={windConditions}
            ></MapPointLeyer>

            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              position="absolute"
              bottom="5%"
              left={7}
              width="100%"
            >
              <MapLegendCard></MapLegendCard>
            </SoftBox>
            <Card
              sx={{
                position: "absolute",
                top: 10,
                left: 10,
                width: "20%",
                borderRadius: "5px",
                padding: "15px",
              }}
            >
              <ControllerCard
                description="Wind conditions"
                state={windConditions}
                onChange={() => setWindConditions(!windConditions)}
              ></ControllerCard>
            </Card>
          </SoftBox>
        </Fade>
      </SoftBox>
    </Card>
  );
}

AirQualityMap.defaultProps = {
  center: {
    lat: -104.99,
    lng: 39.7392,
  },
  zoom: 10,
};

// Typechecking props for the Map
AirQualityMap.propTypes = {
  center: PropTypes.object,
  source: PropTypes.any,
  zoom: PropTypes.number,
  getProperties: PropTypes.func,
};

export default AirQualityMap;
