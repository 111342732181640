// import { useMemo } from "react";
import Chart from "react-apexcharts";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// ChartCard configurations
import configs from "./config";

function ChartCard({ height, title, address, seriesArray }) {
  const { series, options } = configs(seriesArray);

  return (
    <Card>
      <SoftBox p={2} lineHeight={1}>
        <SoftTypography variant="h5" fontWeight="bold" color="dark">
          {title}
        </SoftTypography>
        <SoftTypography
          variant="button"
          textTransform="capitalize"
          fontWeight="medium"
          color="text"
        >
          {address}&nbsp;
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={2}>
        <Chart options={options} series={series} type="area" height={height} />
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of ChartCard
ChartCard.defaultProps = {
  height: 250,
};

// Typechecking props for the ChartCard
ChartCard.propTypes = {
  height: PropTypes.number,
  title: PropTypes.string,
  address: PropTypes.string,
  seriesArray: PropTypes.array,
};

export default ChartCard;
