import PropTypes from "prop-types";
import { useState } from "react";

// @mui material components
import Fade from "@mui/material/Fade";
import { Card } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Maplibre;
import { MapLibreMap } from "@mapcomponents/react-maplibre";
import "./styles.css";

// Soft UI Dashboard PRO React base styles
import MapLegendCard from "components/MapCard/MapLegendCard";
import ControllerCard from "components/ControllerCard";
import MapSymbolLayer from "components/MapCard/MapLayers/MapSymbolLayer";
import MapLinestringLeyer from "components/MapCard/MapLayers/MapLinestringLayer";
import sampleData from "api/data/roadConditionsSambleData";
import mapSourceData from "api/data/mapSourceData";

const mapOptions = {
  zoom: 13,
  style: "https://wms.wheregroup.com/tileserver/style/osm-fiord-color.json",
  center: [12.568069, 55.710079, 26.24],
};

// const controllers = ["potholes", "expansion-joints", "cracking", "bumps", "traffic-conditions"];

function RoadConditionsMap({ getProperties }) {
  const [potholes, setPotholes] = useState(false);
  const [expansionJoints, setExpansionJoints] = useState(false);
  const [roadCracking, setRoadCracking] = useState(false);
  const [bumps, setBumps] = useState(false);
  // const [trafficConditions, setTrafficConditions] = useState(false);
  const features = sampleData.data.rows.map((row) => row[0]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundSize: "cover",
        backgroundPosition: "50%",
        overflow: "visible",
        height: "100%",
      }}
    >
      <SoftBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          position: "relative",
          minHeight: pxToRem(350),

          [breakpoints.up("xl")]: {
            height: "100%",
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <Fade in timeout={850}>
          <SoftBox width="100%" height="100%" position="relative" borderRadius="lg">
            <SoftBox
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              borderRadius="lg"
              sx={{ objectFit: "cover", objectPosition: "center" }}
            />

            <MapLibreMap options={mapOptions} mapId="road-conditions-map" />
            <MapLinestringLeyer
              mapId="road-conditions-map"
              layerId="road-conditions-map"
              onHandleClick={getProperties}
              source={{
                type: "geojson",
                data: { type: "FeatureCollection", features: features },
              }}
              visible={true}
            ></MapLinestringLeyer>
            <MapSymbolLayer
              mapId="road-conditions-map"
              layerId="potholes"
              source={mapSourceData}
              imageSource="http://thesource.metro.net/wp-content/uploads/2012/06/2417954198_77f9a7522b_z2.jpg"
              visible={potholes}
            ></MapSymbolLayer>
            <MapSymbolLayer
              mapId="road-conditions-map"
              layerId="expansion-joints"
              imageSource="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaL3LzCEa1Nd_uuzPIRehRYrIVFhBsDSpTAA&usqp=CAU"
              source={mapSourceData}
              visible={expansionJoints}
            ></MapSymbolLayer>
            <MapSymbolLayer
              mapId="road-conditions-map"
              layerId="road-cracking"
              imageSource="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZBLbVQY0WyYVyYIzIyi0CSl1j8ls38RThqw&usqp=CAU"
              source={mapSourceData}
              visible={roadCracking}
            ></MapSymbolLayer>
            <MapSymbolLayer
              mapId="road-conditions-map"
              layerId="bumps"
              imageSource="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtRQZ_0gFfLjTD1fBjZXQ9l-iY_H3N9ueTrg&usqp=CAU"
              source={mapSourceData}
              visible={bumps}
            ></MapSymbolLayer>
            {/* <MapSymbolLayer
              mapId="road-conditions-map"
              layerId="traffic-conditions"
              imageSource="http://thesource.metro.net/wp-content/uploads/2012/06/2417954198_77f9a7522b_z2.jpg"
              source={mapSourceData}
              visible={trafficConditions}
            ></MapSymbolLayer> */}
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              position="absolute"
              bottom="5%"
              left={7}
              width="100%"
            >
              <MapLegendCard></MapLegendCard>
            </SoftBox>
            <Card
              sx={{
                position: "absolute",
                top: 10,
                left: 10,
                width: "20%",
                borderRadius: "5px",
                padding: "15px",
              }}
            >
              <ControllerCard
                description="Potholes"
                state={potholes}
                onChange={() => setPotholes(!potholes)}
              ></ControllerCard>
              <ControllerCard
                description="Expansion joints"
                state={expansionJoints}
                onChange={() => setExpansionJoints(!expansionJoints)}
              ></ControllerCard>
              <ControllerCard
                description="Road cracking"
                state={roadCracking}
                onChange={() => setRoadCracking(!roadCracking)}
              ></ControllerCard>
              <ControllerCard
                description="Bumps"
                state={bumps}
                onChange={() => setBumps(!bumps)}
              ></ControllerCard>
              {/* <ControllerCard
                description="Traffic conditions"
                state={trafficConditions}
                onChange={() => setTrafficConditions(!trafficConditions)}
              ></ControllerCard> */}
            </Card>
          </SoftBox>
        </Fade>
      </SoftBox>
    </Card>
  );
}

RoadConditionsMap.defaultProps = {};

// Typechecking props for the Map
RoadConditionsMap.propTypes = {
  getProperties: PropTypes.func,
};

export default RoadConditionsMap;
