// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function MapLegendCard() {
  const legend = [
    { id: 1, title: "Very Good", color: "#77c17f" },
    { id: 2, title: "Good", color: "#BCDC9C" },
    { id: 3, title: "Average", color: "#FFDF70" },
    { id: 4, title: "Poor", color: "#EE4A4A" },
    { id: 5, title: "Very Poor", color: "#A30000" },
    { id: 6, title: "Hazardous", color: "#7A0000" },
  ];
  return (
    <SoftBox component="ul" display="flex" flexWrap="wrap" p={0} m={0}>
      {legend.map((item) => (
        <SoftBox
          key={item.id}
          component="li"
          bgColor={item.color}
          sx={{
            listStyle: "none",
          }}
        >
          <SoftBox px={2} lineHeight={1}>
            <SoftTypography variant="button" fontWeight="medium" color="white">
              {item.title}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ))}
    </SoftBox>
  );
}

export default MapLegendCard;
