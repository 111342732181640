import { useEffect } from "react";
import { useMap } from "@mapcomponents/react-maplibre";

// eslint-disable-next-line react/prop-types
function MapPointLeyer({ mapId, insertBeforeLayer, source, visible, layerId, onHandleClick }) {
  const mapHook = useMap({
    mapId: mapId,
    waitForLayer: insertBeforeLayer,
  });

  useEffect(() => {
    if (!mapHook.map) return;

    mapHook.map.addLayer(
      {
        id: layerId,
        type: "circle",
        source: source,
        layout: {
          visibility: visible ? "visible" : "none",
        },
        paint: {
          "circle-radius": 6,
          "circle-color": ["get", "color"],
        },
        filter: ["==", "$type", "Point"],
      },
      insertBeforeLayer,
      mapHook.MapPointLeyer
    );

    mapHook.map.on("click", layerId, (e) => {
      onHandleClick(e.features[0].properties);
    });

    return () => {
      mapHook.cleanup();
    };
  }, [mapHook.map]);

  useEffect(() => {
    if (!mapHook.map) return;
    if (!visible) {
      mapHook.map.setLayoutProperty(layerId, "visibility", "none");
    } else {
      mapHook.map.setLayoutProperty(layerId, "visibility", "visible");
    }
  }, [visible]);

  return <></>;
}

export default MapPointLeyer;
