// @mui material components
import Grid from "@mui/material/Grid";
import { useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// General dashboard components
import StatisticsCard from "components/StatisticsCard";
import SummaryCard from "components/SummaryCard";
import ChartCard from "components/ChartCard";
import ForecastCard from "components/ForecastCard";
import AirQualityMap from "./AirQualityMap";

function AirQualityDashboard() {
  const [geoJsonProperties, setGeoJsonProperties] = useState({
    state: "",
    color: "#BCDC9C",
    number: undefined,
    address: "",
  });

  const getProperties = (data) => {
    setGeoJsonProperties(data);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox sx={{ height: "95%" }}>
        <Grid container spacing={2} direction="row" alignItems="stretch" height="100%">
          <Grid item xs={12} xl={8}>
            <AirQualityMap getProperties={getProperties} />
          </Grid>
          <Grid item xs={12} xl={4}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <StatisticsCard
                      properties={geoJsonProperties}
                      measurement="AQI"
                      percentage={{ color: "dark", label: "Overview" }}
                      action={{ type: "external", route: "", label: "Overview" }}
                    ></StatisticsCard>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SummaryCard
                      col1="Pollutants"
                      col2="Concentration"
                      color="secondary"
                    ></SummaryCard>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <ChartCard
                  title="Historic air quality graph"
                  address="H. C. Andersens Boulevard, Copenhagen"
                  properties={geoJsonProperties}
                ></ChartCard>
              </Grid>
              <Grid item>
                <ForecastCard
                  title="Air quality"
                  address="H. C. Andersens Boulevard, Copenhagen"
                ></ForecastCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default AirQualityDashboard;
