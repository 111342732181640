const mapSourceData = {
  type: "geojson",
  data: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          name: "potholes",
          address: "Lyngbyvej",
          color: "#7A0000",
          number: 8,
          state: "hazardous",
        },
        geometry: {
          type: "Point",
          coordinates: [12.552209, 55.708024, 17.46],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "potholes",
          address: "Lyngbyvej",
          color: "#BCDC9C",
          state: "good",
          number: 4,
        },
        geometry: {
          type: "Point",
          coordinates: [12.555645, 55.706138, 13.77],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "expansion-joints",
          address: "Lyngbyvej",
          color: "#FFDF70",
          state: "moderate",
          number: 10,
        },
        geometry: {
          type: "Point",
          coordinates: [12.549204, 55.705799, 32.84],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "expansion-joints",
          address: "Lyngbyvej",
          color: "#FF7458",
          state: "vulnerable",
          number: 9,
        },
        geometry: {
          type: "Point",
          coordinates: [12.510397, 55.780971, 44.23],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "road-cracking",
          address: "Lyngbyvej",
          color: "#7A0000",
          state: "hazardous",
          number: 8,
        },
        geometry: {
          type: "Point",
          coordinates: [12.511956, 55.783669, 45.18],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "road-cracking",
          address: "Lyngbyvej",
          color: "#FFDF70",
          state: "moderate",
          number: 7,
        },
        geometry: {
          type: "Point",
          coordinates: [12.560072, 55.713128, 13.76],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "bumps",
          address: "Lyngbyvej",
          color: "#7A0000",
          state: "hazardous",
          number: 8,
        },
        geometry: {
          type: "Point",
          coordinates: [12.542668, 55.735322, 27.69],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "bumps",
          address: "Lyngbyvej",
          color: "#FFDF70",
          state: "moderate",
          number: 7,
        },
        geometry: {
          type: "Point",
          coordinates: [12.529012, 55.748064, 25.28],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "traffic-conditions",
          address: "Lyngbyvej",
          color: "#7A0000",
          state: "hazardous",
          number: 8,
        },
        geometry: {
          type: "Point",
          coordinates: [12.53337, 55.744058, 18.28],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "traffic-conditions",
          address: "Lyngbyvej",
          color: "#FFDF70",
          state: "moderate",
          number: 7,
        },
        geometry: {
          type: "Point",
          coordinates: [12.5196, 55.765217, 30.91],
        },
      },
      {
        type: "Feature",
        properties: {
          name: "street-light",
          address: "Lyngbyvej",
          color: "#FFDF70",
          state: "moderate",
          number: 7,
        },
        geometry: {
          type: "Point",
          coordinates: [12.5216, 55.769217, 30.91],
        },
      },
    ],
  },
};

export default mapSourceData;
