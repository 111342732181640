// @mui material components
import Grid from "@mui/material/Grid";
import { useState } from "react";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// General dashboard components
import StatisticsCard from "components/StatisticsCard";
// import SummaryCard from "components/SummaryCard";
import ChartCard from "components/ChartCard";
import ForecastCard from "components/ForecastCard";
import NoisePollutionMap from "./NoisePollutionMap";

const noisePollutionSeries = [
  {
    name: "dBA",
    data: [31, 55, 42, 51, 42, 109, 100],
  },
];

function NoisePollutionDashboard() {
  const [geoJsonProperties, setGeoJsonProperties] = useState({
    state: "",
    color: "#7A0000",
    number: undefined,
    address: "",
  });
  const getProperties = (data) => {
    if (data.number === 1) {
      setGeoJsonProperties({
        state: "hazardous",
        color: "#7A0000",
        number: data.number,
        address: data.address,
      });
    } else if (data.number >= 2 && data.number < 4) {
      setGeoJsonProperties({
        state: "very severe",
        color: "#A30000",
        number: data.number,
        address: data.address,
      });
    } else if (data.number >= 4 && data.number < 6) {
      setGeoJsonProperties({
        state: "severe",
        color: "#EE4A4A",
        number: data.number,
        address: data.address,
      });
    } else if (data.number >= 6 && data.number < 8) {
      setGeoJsonProperties({
        state: "moderate",
        color: "#FF7458",
        number: data.number,
        address: data.address,
      });
    } else if (data.number >= 8 && data.number < 10) {
      setGeoJsonProperties({
        state: "slight",
        color: "#FFDF70",
        number: data.number,
        address: data.address,
      });
    } else {
      setGeoJsonProperties({
        state: "good",
        color: "#BCDC9C",
        number: data.number,
        address: data.address,
      });
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox sx={{ height: "95%" }}>
        <Grid container spacing={2} direction="row" alignItems="stretch" height="100%">
          <Grid item xs={12} xl={8}>
            <NoisePollutionMap getProperties={getProperties} />
          </Grid>
          <Grid item xs={12} xl={4}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <StatisticsCard
                      properties={geoJsonProperties}
                      measurement="dBA"
                      percentage={{ color: "dark", label: "Overview" }}
                      action={{ type: "external", route: "", label: "Overview" }}
                    ></StatisticsCard>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <SummaryCard col1="Condition" col2="Indicators" color="secondary"></SummaryCard>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid item>
                <ChartCard
                  title="Historic noise pollution graph"
                  address="H. C. Andersens Boulevard, Copenhagen"
                  properties={geoJsonProperties}
                  seriesArray={noisePollutionSeries}
                ></ChartCard>
              </Grid>
              <Grid item>
                <ForecastCard
                  title="Noise pollution forecast"
                  address="H. C. Andersens Boulevard, Copenhagen"
                ></ForecastCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default NoisePollutionDashboard;
