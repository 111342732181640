import PropTypes from "prop-types";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ControllerCard({ description, state, onChange }) {
  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={1}
        lineHeight={1}
      >
        <SoftTypography variant="button" color={state ? "dark" : "text"}>
          {description}
        </SoftTypography>
        <SoftBox>
          <Switch checked={state} onChange={onChange} />
        </SoftBox>
      </SoftBox>
    </>
  );
}

// Typechecking props for the ControllerCard
ControllerCard.propTypes = {
  description: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

export default ControllerCard;
