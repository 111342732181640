// import { useWeatherData } from "hooks/useWeatherData";

function configs(seriesArray) {
  // const { chartArray } = useWeatherData("63286fbe1645061e9c16f61b");
  // const hourArray = chartArray.map((item) => item.time);
  // const airTemperatureArray = chartArray.map((item) => item.air_temperature);
  // const dewPointArray = chartArray.map((item) => item.dew_point);
  // const precipitationArray = chartArray.map((item) => item.precipitation_probability);
  // const newarraycolor = airTemperatureArray.map((item) => (item < 6 ? "#21BAFE" : "#E91E63"));

  return {
    series: seriesArray,
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };
}

export default configs;
