import PropTypes from "prop-types";
import { useState } from "react";

// @mui material components
import Fade from "@mui/material/Fade";
import { Card } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Maplibre;
import { MapLibreMap } from "@mapcomponents/react-maplibre";
import "./styles.css";

// Soft UI Dashboard PRO React base styles
import MapLegendCard from "./MapLegendCard";
import ControllerCard from "../ControllerCard";
import MapSymbolLayer from "./MapLayers/MapSymbolLayer";
import MapLinestringLeyer from "./MapLayers/MapLinestringLayer";

const mapOptions = {
  zoom: 14,
  style: "https://wms.wheregroup.com/tileserver/style/osm-bright.json",
  center: [12.582503005479332, 55.695120602559411],
};

// eslint-disable-next-line no-unused-vars
function Map({ layer, source }) {
  const [airQualityIndex, setAirQualityIndex] = useState(true);
  const [windConditions, setWindConditions] = useState(true);
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundSize: "cover",
        backgroundPosition: "50%",
        overflow: "visible",
        height: "100%",
      }}
    >
      <SoftBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          position: "relative",
          minHeight: pxToRem(350),

          [breakpoints.up("xl")]: {
            height: "100%",
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <Fade in timeout={850}>
          <SoftBox width="100%" height="100%" position="relative" borderRadius="lg">
            <SoftBox
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              borderRadius="lg"
              sx={{ objectFit: "cover", objectPosition: "center" }}
            />

            <MapLibreMap options={mapOptions} mapId="map_1" />
            <MapSymbolLayer source={source} visible={windConditions}></MapSymbolLayer>
            <MapLinestringLeyer
              source={source}
              visible={airQualityIndex}
              layerId={layer}
            ></MapLinestringLeyer>

            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              position="absolute"
              bottom="5%"
              left={7}
              width="100%"
            >
              <MapLegendCard></MapLegendCard>
            </SoftBox>
            <Card
              sx={{
                position: "absolute",
                top: 10,
                left: 10,
                width: "20%",
                borderRadius: "5px",
                padding: "15px",
              }}
            >
              <SoftBox
                p={2}
                bgColor="success"
                opacity={0.2}
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <ControllerCard
                  description="Air Quality Index"
                  state={airQualityIndex}
                  onChange={() => setAirQualityIndex(!airQualityIndex)}
                ></ControllerCard>
                <ControllerCard
                  description="Wind Conditions"
                  state={windConditions}
                  onChange={() => setWindConditions(!windConditions)}
                ></ControllerCard>
              </SoftBox>
            </Card>
          </SoftBox>
        </Fade>
      </SoftBox>
    </Card>
  );
}

Map.defaultProps = {
  center: {
    lat: -104.99,
    lng: 39.7392,
  },
  zoom: 10,
};

// Typechecking props for the Map
Map.propTypes = {
  center: PropTypes.object,
  source: PropTypes.any,
  zoom: PropTypes.number,
  layer: PropTypes.any,
};

export default Map;
