import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useMap } from "@mapcomponents/react-maplibre";
import warningSign from "assets/images/icons/warning-triangle-red.svg";
import MapMarkerInfo from "../../MapMarkerInfo";

function MapSymbolLayer({ mapId, layerId, source, visible, imageSource }) {
  const [showMarkerInfo, setShowMarkerInfo] = useState(false);
  const [properties, setProperties] = useState({});
  const mapHook = useMap({
    mapId: mapId,
  });
  const onPopupClose = () => {
    setShowMarkerInfo(false);
  };

  useEffect(() => {
    if (!mapHook.map) return;

    let img = new Image(25, 20);
    img.src = warningSign;
    img.onload = () => mapHook.map.addImage("svgIcon", img);

    mapHook.map.addLayer(
      {
        id: layerId,
        type: "symbol",
        source: source,
        layout: {
          visibility: visible ? "visible" : "none",
          "icon-image": "svgIcon",
          "icon-size": 1.2,
        },
        paint: {
          "icon-color": ["get", "color"],
        },
        filter: ["==", "$type", "Point"],
      },
      mapHook.MapSymbolLayer
    );

    mapHook.map.on("click", layerId, (e) => {
      setShowMarkerInfo(true);
      setProperties(e.features[0].properties);
    });

    return () => {
      mapHook.cleanup();
    };
  }, [mapHook.map]);

  useEffect(() => {
    if (!mapHook.map) return;
    if (!visible) {
      mapHook.map.setLayoutProperty(layerId, "visibility", "none");
      mapHook.map.setFilter(layerId, null);
      setShowMarkerInfo(false);
    } else {
      mapHook.map.setLayoutProperty(layerId, "visibility", "visible");
      mapHook.map.setFilter(layerId, ["==", ["get", "name"], layerId]);
    }
  }, [visible]);

  return (
    <>
      {showMarkerInfo && (
        <MapMarkerInfo
          src={imageSource}
          onClose={onPopupClose}
          properties={properties}
        ></MapMarkerInfo>
      )}
    </>
  );
}
// Typechecking props for the Map
MapSymbolLayer.propTypes = {
  mapId: PropTypes.string,
  layerId: PropTypes.string,
  visible: PropTypes.bool,
  source: PropTypes.any,
  imageSource: PropTypes.string,
};

export default MapSymbolLayer;
