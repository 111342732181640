import { useEffect } from "react";
import { useMap } from "@mapcomponents/react-maplibre";
// import MapPopupCard from "../../MapPopupCard";

// eslint-disable-next-line react/prop-types, no-unused-vars
function MapLinestringLeyer({ mapId, layerId, insertBeforeLayer, source, visible, onHandleClick }) {
  const mapHook = useMap({
    mapId: mapId,
    waitForLayer: insertBeforeLayer,
  });
  // const [popupLngLat, setPopupLngLat] = useState(null);

  // function onPopupClose() {
  //   setPopupLngLat(null);
  // }

  useEffect(() => {
    if (!mapHook.map) return;

    mapHook.map.addSource(layerId, {
      type: "geojson",
      lineMetrics: true,
      // eslint-disable-next-line react/prop-types
      data: source.data,
    });

    mapHook.map.addLayer(
      {
        id: layerId,
        type: "line",
        source: layerId,
        layout: {
          visibility: visible ? "visible" : "none",
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": [
            "step",
            ["get", "number"],
            "#A30000",
            2,
            "#EE4A4A",
            4,
            "#FF7458",
            6,
            "#FFDF70",
            8,
            "#BCDC9C",
            10,
            "#BCDC9C",
          ],
          "line-width": 6,
          "line-blur": 1,
        },
        filter: ["==", "$type", "LineString"],
      },
      insertBeforeLayer,
      mapHook.MapLinestringLeyer
    );
    mapHook.map.on("click", layerId, (e) => {
      onHandleClick(e.features[0].properties);
    });

    return () => {
      mapHook.cleanup();
    };
  }, [mapHook.map]);

  useEffect(() => {
    if (!mapHook.map) return;
    if (!visible) {
      mapHook.map.setLayoutProperty(layerId, "visibility", "none");
    } else {
      mapHook.map.setLayoutProperty(layerId, "visibility", "visible");
    }
  }, [visible]);

  return <></>;
}

export default MapLinestringLeyer;
