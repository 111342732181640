// @mui material components
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

// General dashboard components
import MapCard from "components/MapCard";

function MainDashboard() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox sx={{ height: "95%" }}>
        <Grid container spacing={3} direction="row" alignItems="stretch" height="100%">
          <Grid item xs={12}>
            <MapCard />
          </Grid>
        </Grid>
      </SoftBox>

      {/* <SoftBox my={6} width="100%">
        <Divider />
      </SoftBox> */}
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default MainDashboard;
