// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Images
import whiteCurved from "assets/images/curved-images/white-curved.jpeg";

function SummaryCard({ color, col1, col2 }) {
  return (
    <Card
      sx={({ functions: { linearGradient, rgba }, palette: { gradients } }) => ({
        backgroundImage: gradients[color]
          ? `${linearGradient(
              rgba(gradients[color].main, 0.05),
              rgba(gradients[color].state, 0.05)
            )}, url(${whiteCurved})`
          : `${linearGradient(
              rgba(gradients[color].main, 0.05),
              rgba(gradients[color].state, 0.05)
            )}, url(${whiteCurved})`,
        backgroundSize: "cover",
        backgroundPosition: "50%",
        overflow: "visible",
        height: "100%",
      })}
    >
      <SoftBox
        p={2}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        height="100%"
      >
        <SoftBox display="flex" justifyContent="space-between" my={2}>
          <SoftTypography variant="h6" fontWeight="medium">
            {col1}
          </SoftTypography>
          <SoftTypography variant="h6" fontWeight="medium">
            {col2}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftTypography variant="body2" fontWeight="medium">
            PCI:
          </SoftTypography>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium">
              20
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftTypography variant="body2" fontWeight="medium">
            PSR:
          </SoftTypography>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium">
              2
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftTypography variant="body2" fontWeight="medium">
            IRI:
          </SoftTypography>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium">
              4
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of SummaryCard
SummaryCard.defaultProps = {
  color: "info",
};

// Typechecking props for the SummaryCard
SummaryCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  col1: PropTypes.string.isRequired,
  col2: PropTypes.string.isRequired,
};

export default SummaryCard;
